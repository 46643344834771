// LandingContent.jsx
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import BackgroundColorSrc from '../../img/img_landing_background.svg';
import LandingMainHeaderSrc from '../../img/landing-main-header.jpg';
import DropDownSrc from '../../img/ic-dropdown-white.svg';
import FreeShortSrc from '../../img/ic-landing-short.svg';
import LivestreamSrc from '../../img/ic-landing-live.svg';
import Livestream24hSrc from '../../img/ic-landing-24h.svg';
import LandingSection1Src from '../../img/landing-section-1.png';
import LandingSection2Src from '../../img/landing-section-2.png';
import LandingSection3Src from '../../img/landing-section-3.png';
import LandingCP1Src from '../../img/cp1.png';
import LandingCP2Src from '../../img/cp2.png';
import LandingCP3Src from '../../img/cp3.png';
import LandingCP4Src from '../../img/cp4.png';
import LandingCP5Src from '../../img/cp5.png';

const CPList = [
  LandingCP1Src.src || LandingCP1Src,
  LandingCP2Src.src || LandingCP2Src,
  LandingCP3Src.src || LandingCP3Src,
  LandingCP4Src.src || LandingCP4Src,
  LandingCP5Src.src || LandingCP5Src,
];

import media from '../style/media.js';
import { color } from '../style/variables.js';
import ResetButtonStyle from '../style/ResetButtonStyle.js';
import FadeOut from '../style/FadeOut.js';

import { TranslationNamespace } from '../resource/translationNamespace.js';
import { ButtonId, ModalId } from '../resource/mixpanel.js';

const trackingPayload = {
  modalId: ModalId.Landing.LandingPage2024Iwin,
};

export const LandingContent = ({ status, isOnMobile, login, brandname }) => {
  const { t } = useTranslation();
  const wapperRef = useRef(null);
  const goToBackRef = useRef(null);
  useEffect(() => {
    const wrapperElm = wapperRef.current;
    function scrollFunction() {
      if (wrapperElm.scrollTop > 20) {
        goToBackRef.current.style.display = 'block';
      } else {
        goToBackRef.current.style.display = 'none';
      }
    }
    wrapperElm.addEventListener('scroll', scrollFunction);
    return () => {
      wrapperElm.removeEventListener('scroll', scrollFunction);
    };
  }, []);
  const scrollToTop = () => {
    if (wapperRef.current) {
      wapperRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <Wrapper $status={status} ref={wapperRef}>
      <BackgroundTopGradient />
      <Container>
        <StyledLandingContent>
          <Header>
            <SWAGLogo
              src="/logo/landing"
              alt={brandname}
              width={69}
              height={30}
            />
            <LoginRegisterButton
              type="Button"
              data-element_id={ButtonId.LoginRegister.ButtonSignup}
              data-tracking_payload={trackingPayload}
              onClick={login}
            >
              {t('iwin_landing_page_sec_cta', {
                ns: TranslationNamespace.AUTH,
              })}
            </LoginRegisterButton>
          </Header>
          <Main>
            <MainTitleBox>
              <MainTitleSWAG>SWAG.live</MainTitleSWAG>
              <MainTitle>
                {t('iwin_landing_page_title', {
                  ns: TranslationNamespace.GENERAL,
                })}
              </MainTitle>
              <MainSubtitle>
                {t('iwin_landing_page_subtitle', {
                  ns: TranslationNamespace.GENERAL,
                })}
              </MainSubtitle>
              {!isOnMobile && (
                <WatchNowButton
                  type="button"
                  data-element_id={ButtonId.Landing.LandingPageCta}
                  data-tracking_payload={trackingPayload}
                  onClick={login}
                >
                  {t('iwin_landing_page_cta', {
                    ns: TranslationNamespace.GENERAL,
                  })}
                </WatchNowButton>
              )}
            </MainTitleBox>
            <MainImage
              src={LandingMainHeaderSrc.src || LandingMainHeaderSrc}
              alt="a sexy woman lying bed"
              width={483}
              height={322}
            />
            <MainInfoBox>
              <MainInfoCard>
                <MainInfoNumber>
                  1K <BluePlus>+</BluePlus>
                </MainInfoNumber>
                <MainInfoText>
                  {t('iwin_landing_page_statistic_1', {
                    ns: TranslationNamespace.GENERAL,
                  })}
                </MainInfoText>
              </MainInfoCard>
              <MainInfoCard>
                <MainInfoNumber>
                  10K <BluePlus>+</BluePlus>
                </MainInfoNumber>
                <MainInfoText>
                  {t('iwin_landing_page_statistic_2', {
                    ns: TranslationNamespace.GENERAL,
                  })}
                </MainInfoText>
              </MainInfoCard>
              <MainInfoCPStreamersBox>
                <MainInfoCPList>
                  {CPList.map((src, index) => (
                    <CPImage
                      key={src}
                      src={src}
                      alt={`SWAG Creator ${index}`}
                      width={48}
                      height={48}
                    />
                  ))}
                </MainInfoCPList>
                <MainInfoCPStreamersNumber>
                  1k<BluePlus>+</BluePlus>{' '}
                </MainInfoCPStreamersNumber>
                <MainInfoCPStreamersText>
                  {t('iwin_landing_page_statistic_3', {
                    ns: TranslationNamespace.GENERAL,
                  })}
                </MainInfoCPStreamersText>
              </MainInfoCPStreamersBox>
            </MainInfoBox>
            {isOnMobile && (
              <WatchNowButton
                type="button"
                data-element_id={ButtonId.Landing.LandingPageCta}
                data-tracking_payload={trackingPayload}
                onClick={login}
              >
                {t('iwin_landing_page_cta', {
                  ns: TranslationNamespace.GENERAL,
                })}
              </WatchNowButton>
            )}
          </Main>
          <DropDownWrapper>
            <DropDown href="#section-area">
              <img src={DropDownSrc} alt="" width={20} height={20} />
            </DropDown>
          </DropDownWrapper>
          <ContentSection id="section-area">
            <ContentSectionImageBox>
              <ContentSectionImage
                src={LandingSection1Src.src || LandingSection1Src}
                alt="SWAG homepage and livestream page"
                width={500}
                height={480}
              />
            </ContentSectionImageBox>
            <ContentSectionTextBox>
              <ContentSectionTitleBox>
                <ContentSectionTitleIconBox>
                  <ContentSectionTitleIcon
                    src={FreeShortSrc}
                    alt=""
                    width={60}
                    height={60}
                  />
                </ContentSectionTitleIconBox>
                <ContentSectionTitle>
                  {t('iwin_landing_page_section_1_title', {
                    ns: TranslationNamespace.GENERAL,
                  })}
                </ContentSectionTitle>
              </ContentSectionTitleBox>
              <ContentSectionTextList>
                <ContentSectionText>
                  {t('iwin_landing_page_section_1_body_1', {
                    ns: TranslationNamespace.GENERAL,
                  })}
                </ContentSectionText>
                <ContentSectionText>
                  {t('iwin_landing_page_section_1_body_2', {
                    ns: TranslationNamespace.GENERAL,
                  })}
                </ContentSectionText>
                <ContentSectionText>
                  {t('iwin_landing_page_section_1_body_3', {
                    ns: TranslationNamespace.GENERAL,
                  })}
                </ContentSectionText>
              </ContentSectionTextList>
            </ContentSectionTextBox>
          </ContentSection>
          <ContentSection>
            <ContentSectionTextBox>
              <ContentSectionTitleBox>
                <ContentSectionTitleIconBox>
                  <ContentSectionTitleIcon
                    src={LivestreamSrc}
                    alt=""
                    width={60}
                    height={60}
                  />
                </ContentSectionTitleIconBox>
                <ContentSectionTitle>
                  {t('iwin_landing_page_section_2_title', {
                    ns: TranslationNamespace.GENERAL,
                  })}
                </ContentSectionTitle>
              </ContentSectionTitleBox>
              <ContentSectionTextList>
                <ContentSectionText>
                  {t('iwin_landing_page_section_2_body_1', {
                    ns: TranslationNamespace.GENERAL,
                  })}
                </ContentSectionText>
                <ContentSectionText>
                  {t('iwin_landing_page_section_2_body_2', {
                    ns: TranslationNamespace.GENERAL,
                  })}
                </ContentSectionText>
                <ContentSectionText>
                  {t('iwin_landing_page_section_2_body_3', {
                    ns: TranslationNamespace.GENERAL,
                  })}
                </ContentSectionText>
              </ContentSectionTextList>
            </ContentSectionTextBox>
            <ContentSectionImageBox>
              <ContentSectionImage
                src={LandingSection2Src.src || LandingSection2Src}
                alt="Swag creators grid list"
                width={500}
                height={480}
              />
            </ContentSectionImageBox>
          </ContentSection>
          <ContentSection>
            <ContentSectionImageBox>
              <ContentSectionImage
                src={LandingSection3Src.src || LandingSection3Src}
                alt="Livestream pages"
                width={500}
                height={480}
              />
            </ContentSectionImageBox>
            <ContentSectionTextBox>
              <ContentSectionTitleBox>
                <ContentSectionTitleIconBox>
                  <ContentSectionTitleIcon
                    src={Livestream24hSrc}
                    alt=""
                    width={60}
                    height={60}
                  />
                </ContentSectionTitleIconBox>
                <ContentSectionTitle>
                  {t('iwin_landing_page_section_3_title', {
                    ns: TranslationNamespace.GENERAL,
                  })}
                </ContentSectionTitle>
              </ContentSectionTitleBox>
              <ContentSectionTextList>
                <ContentSectionText>
                  {t('iwin_landing_page_section_3_body_1', {
                    ns: TranslationNamespace.GENERAL,
                  })}
                </ContentSectionText>
                <ContentSectionText>
                  {t('iwin_landing_page_section_3_body_2', {
                    ns: TranslationNamespace.GENERAL,
                  })}
                </ContentSectionText>
                <ContentSectionText>
                  {t('iwin_landing_page_section_3_body_3', {
                    ns: TranslationNamespace.GENERAL,
                  })}
                </ContentSectionText>
              </ContentSectionTextList>
            </ContentSectionTextBox>
          </ContentSection>
          <GoToTopButton
            onClick={scrollToTop}
            type="button"
            title="Go to top"
            ref={goToBackRef}
          >
            <GoToTopImage src={DropDownSrc} alt="" width={40} height={40} />
          </GoToTopButton>
        </StyledLandingContent>
        <BackgroundBottomGradient />
      </Container>
    </Wrapper>
  );
};

LandingContent.propTypes = {
  isOnMobile: PropTypes.bool,
  status: PropTypes.string,
  login: PropTypes.func,
  brandname: PropTypes.string,
};

LandingContent.defaultProps = {
  isOnMobile: null,
  status: undefined,
  login: () => null,
  brandname: 'SWAG',
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: ${color.black};
  &::-webkit-scrollbar {
    display: none;
  }
  animation: 0.6s forwards
    ${({ status }) => ('closing' === status ? FadeOut : null)};
`;

const Container = styled.div`
  position: relative;
`;

const BackgroundTopGradient = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-image: url(${BackgroundColorSrc});
  background-repeat: no-repeat;
  background-position: top right;
  z-index: -1;
`;

const BackgroundBottomGradient = styled.div`
  height: 1000px;
  background: linear-gradient(
    0deg,
    #a70064 8.91%,
    rgba(255, 11, 231, 0) 99.86%
  );
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -2;
`;

const StyledLandingContent = styled.div`
  position: relative;
  padding: 40px 0;
  width: 1024px;
  max-width: 1024px;
  margin: auto;
  ${media.tablet`
    padding: 20px 32px;
    width: auto;
    max-width: none;
  `}
  ${media.mobile`
    padding: 16px;
    width: auto;
    max-width: none;
  `}
`;

const Header = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
  ${media.tablet`
    margin-bottom: 40px;
  `}
  ${media.mobile`
    margin-bottom: 24px;
  `}
`;

const SWAGLogo = styled.img`
  width: 148px;
  height: 65px;
  ${media.tablet`
    width: 69px;
    height: 30px;
  `}
`;

const LoginRegisterButton = styled.button`
  ${ResetButtonStyle}
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid white;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  ${media.tablet`
    padding: 4px 8px;
    font-size: 14px;
  `}
`;

const Main = styled.section`
  display: flex;
  gap: 12px;
  justify-content: center;
  margin-bottom: 48px;
  ${media.tablet`
    margin-bottom: 24px;
    gap: 16px;
  `}
  ${media.mobile`
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 328px;
    margin-left: auto;
    margin-right: auto;
  `}
`;

const MainTitleBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
  max-width: 248px;
  ${media.tablet`
    max-width: 184px;
    gap: 6px;
  `}
  ${media.mobile`
    max-width: 100%;
    order: 2;
    gap: 4px;
  `}
`;

const MainTitleSWAG = styled.div`
  font-size: 32px;
  font-style: normal;
  font-weight: 510;
  color: ${color.tealBlue};
  ${media.tablet`
    font-size: 20px;
  `}
  ${media.mobile`
    font-weight: 400;
  `}
`;

const MainTitle = styled.h1`
  font-size: 60px;
  font-weight: 1000;
  line-height: 110%;
  margin: 0;
  padding: 0;
  ${media.tablet`
    font-size: 42px;
    font-weight: 700;
  `}
  ${media.mobile`
    font-size: 40px;
  `}
`;

const MainSubtitle = styled.h2`
  font-size: 20px;
  font-weight: 274;
  line-height: 150%;
  letter-spacing: -0.528px;
  margin: 0;
  padding: 0;
  ${media.tablet`
    font-size: 14px;
    letter-spacing: -0.308px;
  `}
  ${media.mobile`
    font-size: 13px;
    letter-spacing: -0.286px;
  `}
`;

const WatchNowButton = styled.button`
  ${ResetButtonStyle}
  width: 100%;
  height: 58px;
  padding: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  background-color: ${color.tealBlue};
  color: #010101;
  font-size: 24px;
  font-weight: 590;
  ${media.tablet`
    font-size: 16px;
    border-radius: 4px;
    height: 42px;
    padding: 10px;
    margin-top: 6px;

  `}
  ${media.mobile`
    margin-top: 16px;
    font-weight: 700;
    height: 36px;
    order: 4;
  `}
`;

const MainImage = styled.img`
  width: auto;
  height: 322px;
  border-radius: 20px;
  ${media.tablet`
    width: 367px;
    height: 218px;
  `}
  ${media.mobile`
    width: 100%;
    height: 176px;
    order: 1;
  `}
`;

const MainInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  ${media.tablet`
    gap: 4px;
  `}
  ${media.mobile`
    gap: 8px;
    order: 3;
    flex-direction: row;
  `}
`;

const MainInfoCard = styled.div`
  display: flex;
  width: 157px;
  height: 100px;
  padding: 13px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  background: #161616;
  ${media.tablet`
    width: 83px;
    height: 53px;
    padding: 7px 5px;
    gap: 4px;
    border-radius: 5px;
  `}
  ${media.mobile`
    padding: 8px;
    gap: 8px;
    border-radius: 10px;
  `}
`;

const MainInfoNumber = styled.div`
  color: #d9d9d9;
  font-size: 32px;
  font-weight: 1000;
  ${media.tablet`
    font-size: 18px;
  `}
  ${media.mobile`
    font-size: 16px;
  `}
`;

const MainInfoText = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #5e5e5e;
  ${media.tablet`
    font-size: 8px;
  `}
  ${media.mobile`
    font-size: 10px;
  `}
`;

const MainInfoCPStreamersBox = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  margin-top: 12px;
  gap: 8px;
  ${media.tablet`
    margin-top: 8px;
    gap: 4px;
  `}
  ${media.mobile`
    margin-top: 0px;
  `}
`;

const MainInfoCPList = styled.div`
  grid-column: 1/3;
  ${media.mobile`
    grid-column: 1/2;
  `}
`;

const BluePlus = styled.span`
  color: ${color.tealBlue};
`;

const CPImage = styled.img`
  margin-left: -24px;
  :first-child {
    margin-left: 0;
  }
  border-radius: 50%;
  border: 2px solid transparent;
  background: linear-gradient(#a445b3 4%, #d41872 54%, #ff0066) border-box;
  ${media.tablet`
    width: 26px;
    height: 26px;
    margin-left: -13px;
  `}
`;

const MainInfoCPStreamersNumber = styled.div`
  font-size: 24px;
  font-weight: 1000;
  background: linear-gradient(
    136deg,
    #a445b3 3.79%,
    #d41872 48.03%,
    #f06 97.19%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${media.tablet`
    font-size: 16px;
  `}
`;

const MainInfoCPStreamersText = styled.div`
  color: #dedede;
  font-size: 20px;
  font-weight: 400;
  ${media.tablet`
    font-size: 10px;
  `}
  ${media.mobile`
    font-size: 12px;
    grid-column: 1/3;
  `}
`;

const DropDownWrapper = styled.div`
  margin-bottom: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.tablet`
    margin-bottom: 24px;
  `}
  ${media.mobile`
    > {
      width: 14px;
      height: 14px;
    }
  `}
`;

const DropDown = styled.a``;

const ContentSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  gap: 12px;
  ${media.tablet`
    gap: 12px;
    gap: 34px;
    margin-bottom: 7px;
  `}
  ${media.mobile`
    font-size: 12px;
    grid-column: 1/3;
    margin-bottom: 24px;
    flex-direction: column;
    gap: 12px;
  `}
`;

const ContentSectionTitleIconBox = styled.div`
  position: relative;
  ${media.tablet`
    width: 40px;
    height: 40px;
  `}
  ${media.mobile`
    width: 30px;
    height: 30px;
  `}
`;

const ContentSectionTitleIcon = styled.img`
  ${media.tablet`
    width: 40px;
    height: 40px;
  `}
  ${media.mobile`
    width: 30px;
    height: 30px;
  `}
`;

const ContentSectionImage = styled.img`
  ${media.tablet`
    width: 340px;
    height: 330px;
  `}
  ${media.mobile`
    width: 328px;
    height: 310px;
  `}
`;

const ContentSectionImageBox = styled.div`
  ${media.mobile`
    order: 1;
  `}
`;

const ContentSectionTextBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${media.tablet`
    gap: 14px;
  `}
  ${media.mobile`
    gap: 8px;
    order: 2;
  `}
`;

const ContentSectionTitleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

const ContentSectionTitle = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 36px;
  font-weight: 1000;
  line-height: 110%;
  ${media.tablet`
    font-size: 24px;
  `}
`;

const ContentSectionTextList = styled.ul`
  margin: 0;
  ${media.mobile`
    gap: 8px;
    list-style: none;
    padding: 0;
  `}
`;

const ContentSectionText = styled.li`
  color: #d9d9d9;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px; /* 150% */
  letter-spacing: 2px;
  ${media.tablet`
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 1.154px;
  `}
  ${media.mobile`
    text-align: center;
  `}
`;

const GoToTopButton = styled.button`
  ${ResetButtonStyle}
  position: sticky;
  top: 0;
  left: 100%;
  bottom: 64px;
  background-color: #acacac99;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  padding: 8px;
  display: none;
  ${media.tablet`
    width: 48px;
    height: 48px;
    bottom: 20px;
  `}
  ${media.mobile`
    bottom: 16px; 
  `}
`;

const GoToTopImage = styled.img`
  width: 100%;
  height: 100%;
  transform: rotate(180deg);
`;

export default LandingContent;
